.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.viewport-header {
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
a{
  text-decoration: none;
}
.links{
  position: relative;
  padding: 1rem
}



h2{
  font-family: 'Syncopate', sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.3vw;
  line-height: 1.2;
  font-size: 1vw;
  text-align: center;
  cursor: pointer;
}
h1 {
  font-family: 'Syncopate', sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 3vw;
  text-align: center;
}
h1 span {
  display: block;
  font-size: 10vw;
  letter-spacing: -1.1vw;
}

@media only screen and (max-width: 600px) {

  h1 {
    font-family: 'Syncopate', sans-serif;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3vw;
    line-height: 1.2;
    font-size: 3vw;
    text-align: center;
  }
  h1 span {
    display: block;
    font-size: 20vw;
    letter-spacing: -1.1vw;
  }
  h2{
    font-family: 'Syncopate', sans-serif;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.3vw;
    line-height: 1.2;
    font-size: 3vw;
    text-align: center;
    cursor: pointer;
  }

}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
